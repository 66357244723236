import { GetTeams } from '@/lib/teams/interface';
import { ByPageData } from '@/types/interface';

export enum SubmissionValidUntilStateEnum {
  Expired = 0,
  Pending = 1,
  Valid = 2,
  None = 3,
}

export enum SubmissionExpirationStateEnum {
  Ongoing = 0,
  Overdue = 1,
  None = 2,
}

export const SubmissionValidUntilState: Record<
  SubmissionValidUntilStateEnum,
  string
> = {
  [SubmissionValidUntilStateEnum.Expired]: 'Expired',
  [SubmissionValidUntilStateEnum.Pending]: 'Pending',
  [SubmissionValidUntilStateEnum.Valid]: 'Valid',
  [SubmissionValidUntilStateEnum.None]: 'None',
};

export const SubmissionExpirationState: Record<
  SubmissionExpirationStateEnum,
  string
> = {
  [SubmissionExpirationStateEnum.Ongoing]: 'Ongoing',
  [SubmissionExpirationStateEnum.Overdue]: 'Overdue',
  [SubmissionExpirationStateEnum.None]: 'None',
};

export enum SubmissionTodoTypeEnum {
  AllTodo = 1,
  MyTurn = 2,
  Ongoing = 3,
  Done = 4,
}

export const SubmissionTodoTypeConstant: Record<
  SubmissionTodoTypeEnum,
  {
    label: string;
    value: SubmissionTodoTypeEnum;
    hashTag: string;
  }
> = {
  [SubmissionTodoTypeEnum.AllTodo]: {
    label: 'All',
    value: SubmissionTodoTypeEnum.AllTodo,
    hashTag: 'all',
  },
  [SubmissionTodoTypeEnum.MyTurn]: {
    label: 'My Turn',
    value: SubmissionTodoTypeEnum.MyTurn,
    hashTag: 'my',
  },
  [SubmissionTodoTypeEnum.Ongoing]: {
    label: 'Ongoing',
    value: SubmissionTodoTypeEnum.Ongoing,
    hashTag: 'ongoing',
  },
  [SubmissionTodoTypeEnum.Done]: {
    label: 'Done',
    value: SubmissionTodoTypeEnum.Done,
    hashTag: 'done',
  },
};

export enum SubmissionHistoryTypeEnum {
  UpdateField = 0,
  SubmitForm = 1,
  SubmitByShareLink = 2,
  Created = 3,
  ManagerSetStep = 4,
  ManagerEditSubmissionDetail = 5,
  ManagerEditSubmissionField = 6,
}

export enum SubmissionHistoryOutcomeTypeEnum {
  BackToPrevious = 0,
  Close = 1,
  CreateNewVersion = 2,
}

export const SubmissionHistoryOutcomeConstant: Record<
  SubmissionHistoryOutcomeTypeEnum,
  string
> = {
  [SubmissionHistoryOutcomeTypeEnum.BackToPrevious]: 'Back to previous',
  [SubmissionHistoryOutcomeTypeEnum.Close]: 'Close',
  [SubmissionHistoryOutcomeTypeEnum.CreateNewVersion]: 'Create new version',
};

export enum SubmissionDetailFieldTypeEnum {
  ReferenceNo = 0,
  ExpirationAt = 1,
  InValidAt = 2,
  SubmittedAt = 3,
}

export const SubmissionDetailFieldTypeConstant: Record<
  SubmissionDetailFieldTypeEnum,
  string
> = {
  [SubmissionDetailFieldTypeEnum.ReferenceNo]: 'Reference No',
  [SubmissionDetailFieldTypeEnum.ExpirationAt]: 'Expiration At',
  [SubmissionDetailFieldTypeEnum.InValidAt]: 'In Valid At',
  [SubmissionDetailFieldTypeEnum.SubmittedAt]: 'Submitted At',
};
export interface CreateSubmissionRequest {
  templateId: number;
  expirationAt: string; // ISO 8601 format
  daysOfReminderBeforeExpiry: number;
}

export interface SubmissionsQuery {
  templateId: number;
  pageNumber?: number;
  pageCount?: number;
  orderBy?: string;
  isOrderByDesc?: boolean;
  keyword?: string;
}

export interface Submitter {
  userId: number;
  fullName: string;
  email: string;
  photoUrl: string;
  abbreviationBgColor: string;
}

export enum ValidUntilStateEnum {
  Expired = 0,
  Pending = 1,
  Valid = 2,
  None = 3,
}

export enum ExpirationStateEnum {
  Ongoing = 0,
  Overdue = 1,
  None = 2,
}

export interface SubmissionData {
  currentStepName: string;
  expirationAt: string;
  expirationState: ExpirationStateEnum;
  fromActionColor: string | null;
  fromActionName: string | null;
  invalidAt: string | null;
  validTimeStart: string | null;
  validTimeEnd: string | null;
  referenceNumberStr: string;
  submissionId: number;
  submittedAt: string | null;
  submitter: Submitter;
  validUntilState: ValidUntilStateEnum;
}
export interface TrashedSubmissionData extends SubmissionData {
  trashedAt: string;
  trashedBy: string;
  isLastStep: boolean;
}

export interface Submission {
  currentPageCount: number;
  data: SubmissionData[];
  pageCount: number;
  pageNumber: number;
  totalCount: number;
  totalPageCount: number;
}

export interface CurrentStepTeam {
  id: number;
  name: string;
  note: string;
}

export interface SubformSubmission {
  submissionId: number;
  created: string;
  submitter: Submitter;
  submittedAt: string | null;
  referenceNumberStr: string;
  currentStepName: string;
  currentStepId: string;
  fromActionId: string | null;
  fromActionName: string | null;
  fromActionColor: string | null;
  expirationAt: string | null;
  invalidAt: string | null;
  validUntilState: number;
  expirationState: number;
  isLastStep: boolean;
}

export interface SubmissionDetail {
  created: string;
  currentStepActions: CurrentStepAction[];
  currentStepId: string;
  currentStepTeams: GetTeams[];
  submissionId: number;
  templateId: number;
  submitter: Submitter;
  submittedAt: string | null;
  referenceNumberStr: string;
  expirationAt: string;
  validTimeStart: string | null;
  validTimeEnd: string | null;
  pages: any[] | null;
  photos: any[];
  files: any[];
  isLastStep: boolean;
  validUntilState: SubmissionValidUntilStateEnum;
  expirationState: SubmissionExpirationStateEnum;
  updated: string;
  templateName: string;
  projectId: number;
  actionAlignment?: 'left' | 'right' | 'center';
  subformSubmissions: SubformSubmission[];
}

export interface CurrentStepAction {
  actionId: string;
  color: string;
  name: string;
}

export interface SubmitStepRequest {
  submissionId: number;
  projectId: number;
  stepId: string;
  actionId: string;
}

export interface SaveStepRequest {
  submissionId: number;
  projectId: number;
  stepId: string;
  pages: any[];
  changes?: Changes[];
}

export interface Changes {
  fieldName: string;
  oldValue: string;
  newValue: string;
}

export interface SubmissionProgress {
  stepRecords: StepRecord[];
}

export interface StepRecord {
  stepId: string;
  stepName: string;
  submissionProcessId: number;
  version: string | null;
  submitter: Submitter | null;
  shareLinkSubmitter: {
    email: string;
    name: string;
    companyName: string;
  };
  submittedAt: string | null;
  submitActionId?: string | null;
  nextNodeType?: string;
  isRevokedStep?: boolean;
  teamIds: number[];
}

export interface BriefQueryParams {
  templateId: number;
  orderBy?: string;
  isOrderByDesc?: boolean;
  keyword?: string;
  pageCount?: number;
  pageNumber?: number;
  currentStepId?: string;
  validUntilState?: SubmissionValidUntilStateEnum;
  expirationState?: SubmissionExpirationStateEnum;
}

interface BriefData {
  submissionId: number;
  referenceNumber: number;
  referenceNumberStr: string;
}

export interface Brief {
  currentPageCount: number;
  pageNumber: number;
  pageCount: number;
  totalCount: number;
  totalPageCount: number;
  data: BriefData[];
}

export interface ProcessDetailRequest {
  submissionId: number;
  version: string;
}

export interface CreateSubformParams {
  formTemplateIds: number[];
  submissionId: number;
  projectId: number;
  stepId: string;
  subformTemplateName: string;
}

export interface SubmissionTodoParams {
  todoType: SubmissionTodoTypeEnum;
  orderBy?: string;
  isOrderByDesc?: boolean;
  keyword?: string;
  pageCount?: number;
  pageNumber?: number;
}

export type SubmissionTodoByPage = ByPageData<SubmissionTodoData>;

export interface SubmissionTodoData {
  created: string;
  currentStepName: string;
  expirationAt: string;
  expirationState: number;
  fromActionColor: string | null;
  fromActionName: string | null;
  invalidAt: string | null;
  isLastStep: boolean;
  project: {
    color: string;
    icon: string;
    name: string;
    projectId: number;
  };
  referenceNumberStr: string;
  submissionId: number;
  submissionTemplateName: string;
  submitter: {
    abbreviationBgColor: string;
    email: string;
    fullName: string;
    isDelete: boolean;
    nameAbbreviation: string;
    photoUrl: string | null;
    userId: number;
  };
  todoType: number;
  validUntilState: number;
}

export interface ShareLinks {
  multipleStepsShareLinks: MultipleStepsShareLink[];
  shareableSteps: ShareableStep[];
  teams: GetTeams[];
}

export interface MultipleStepsShareLink {
  shareLinkId: number;
  stepIds: string[];
  stepNames: string[];
  token: string;
}

export interface ShareableStep {
  shareLinkId: number;
  stepId: string;
  stepName: string;
  token: string;
  submissionId: number;
  teamIds: number[];
}

export interface CreateShareLinkRequest {
  submissionId: number;
  projectId: number;
  stepIds: string[];
}

export interface SubmissionHistoriesByPageFilterParams {
  submissionId: number;
  version: string;
  pageNumber: number;
  pageCount: number;
  orderByField?: string;
  isOrderByDesc?: boolean;
}

export interface SubmissionHistoriesByPageFilter {
  currentPageCount: number;
  pageNumber: number;
  pageCount: number;
  totalCount: number;
  totalPageCount: number;
  data: SubmissionHistory[];
}

export interface SubmissionHistory {
  id: number;
  type: SubmissionHistoryTypeEnum;
  details: HistoryDetails;
  created: string;
}

export interface HistoryDetails {
  creatorName?: string;
  fieldId?: string;
  fieldName?: string;
  pageId?: string;
  pageName?: string;
  pageNumber?: number;
  oldValue?: string;
  oldStepType?: string;
  newValue?: string;
  stepId?: string;
  stepName?: string;
  stepNumber?: number;
  actionId?: string;
  actionName?: string;
  actionColor?: string;
  outcomeType?: SubmissionHistoryOutcomeTypeEnum;
  submitterEmail?: string;
  submitterName?: string;
  submitterCompanyName?: string;
  infoType?: SubmissionDetailFieldTypeEnum;
  oldStepId?: string;
  oldStepName?: string;
  oldStepNumber?: number;
  newStepId?: string;
  newStepName?: string;
  newStepNumber?: number;
}

export interface UpdateByManagerRequest {
  submissionId: number;
  referenceNumber?: number;
  expirationAt?: string;
  invalidAt?: string;
  currentStepId?: string;
}
